<template>
  <div id="checkout-content--left">
   
    <!-- PRODUCT GUARANTEE-->
    <template v-if="displayDiv(posName, 'guaranteeSeal') && !isSizeWindow && data.templateType == 'LeftSidebar'">
      <div
        v-if="
          data.showSeals &&
          ((data.themeType == 'Classic' &&
              data.templateType == 'LeftSidebar' &&
              !isSizeWindow))
        "
        class="checkout-content--left__guarantee"
      >
        <!-- <Seal :data='data' :isSizeWindow='isSizeWindow' :direction='column' /> -->
        <Seal :data="data" :isSizeWindow="isSizeWindow" />
      </div>
    </template>
   
    <div class="checkout-content--left__container">
      <!-- ORDER SUMMARY ON MOBILE DEVICE-->
      <template v-if="displayDiv(posName, 'orderSummary') && !isSizeWindow">
        <div
          class="checkout-content--left__order-summary-mobile mt-2 mb-4 flex justify-between d-md-none isHideBtn"
          v-if="data.themeType == 'Minimalism'"
        >
          <div
            class="flex justify-between align-items-center"
            @click="isHidden = !isHidden"
          >
            <div>
              <img src="@/assets/icons/Cart.svg" alt="check icon" />
            </div>
            <div class="ml-1" style="color: #2e47ba">
              <span v-if="isHidden">{{ $t('common.show') }}</span><span v-else>{{ $t('common.hide') }}</span> 
              {{ $t('common.orderSummary') }}
            </div>
            <div class="ml-1">
              <img src="@/assets/icons/blue-downArrow.svg" alt="check icon" />
            </div>
          </div>
          <div class="hideOrder-price">
            Rp{{ totalPrice | g_number_format }}
          </div>
        </div>
      </template>
      
      <div
        class="embed-checkout-left__container checkout-container"
        :class="[
          !isHidden ? 'd-block' : 'd-none d-md-block',
          data.themeType == 'Classic' ? 'classic' : '',
          (data.themeType === 'NoSidebar' ||  data.themeType === 'NoSidebarNarrowCanvas') && data.themeType === 'Minimalism'
            ? 'pp-minimalism-container'
            : '',
        ]"
      >
        <!-- YOUTUBE -->
         <!-- <div
          class="checkout-content--left__feature embed-checkout-left-youtube youtube-w"
          :class="form && data.templateType == 'NoSidebar' ? '' : 'youtube-h'"
          v-if="data.video && videoId"
        >
          <youtube :video-id="videoId"></youtube>
        </div> -->

        <!-- PRODUCT GUARANTEE ON MOBILE DEVICE-->
        <template v-if="displayDiv(posName, 'guaranteeSeal')">
          <div
            v-if="
              data.showSeals &&
              data.themeType != 'Classic' &&
              data.templateType != 'NoSidebar' &&
              data.templateType != 'NoSidebarNarrowCanvas'
            "
            class="checkout-content--left__feature embed-checkout-left-seals seal-content"
            :class="data.templateType == 'NoSidebar' || data.templateType == 'NoSidebarNarrowCanvas' ? 'leftTemp-seal' : ''"
          >
            <Seal
              v-if="data.themeType != 'Classic'"
              :data="data"
              :isSizeWindow="isSizeWindow"
            />
          </div>
        </template>

        <!-- MAIN CONTENT -->
        <div
          class="checkout-content--left__main"
          :class="[
            data.themeType == 'Classic'
              ? isSizeWindow &&
                [ 
                  'componentVideo',
                  'guaranteeSeal',
                  'productImage',
                  'content',
                  'content_bullets',
                ].includes(posName) &&
                (pos.showHideButton ? pos[pos.buttonVar] : true) &&
                addContainerClass(posName)
                ? 'checkout-content--left__main--container'
                : addContainerClass(posName)
                ? 'checkout-content--left__main--container'
                : ''
              : '',
          ]"
        >

        

          <!-- PRODUCT IMAGE -->
          <template v-if="displayDiv(posName, 'productImage')">
            <no-ssr>
                <ProductImage  v-if="productImages.length > 0"  :productImageSetting="data.productImageSetting" :images="productImages" :autoSlideSettings="data.autoSlideSettings" type="classic" :variantImageIndex="variantImageIndex" />
              </no-ssr>
          </template>
          
            <!-- YOUTUBE -->
            <template v-if="displayDiv(posName, 'componentVideo')">
            <div class="checkout-content--left__feature add-product-preview-right-youtube youtube-w" v-if="data.showVideo && videoUrl!=''">
              <iframe :src="'https://www.youtube.com/embed/' + videoUrl" allowfullscreen></iframe>
              <!-- <youtube :video-id="videoId"></youtube> -->
            </div>
          </template>

          <!-- DESCRIPTION and BULLETS -->
          <div
            v-if="
              data.showContentBullet && displayDiv(posName, 'content_bullets')
            "
            class="checkout-content--left__feature checkout-content--left__main__description-and-bullet"
          >
            <!-- DESCRIPTION -->
            <div
              v-if="data.content"
              class="checkout-content--left__main__description"
              :class="
                isSizeWindow
                  ? 'checkout-content--left__main__description--desktop'
                  : ''
              "
              v-html="data.content"
            ></div>

            <!-- BULLETS -->
            <div
              v-if="data.bullets.length"
              class="checkout-content--left__main__bullet"
              :class="isSizeWindow ? 'embed-bulletPoints' : ''"
            >
              <h2 class="text--subtitle" v-html="data.titleBullet"></h2>
              <div class="bullet__list">
                <div
                  :key="bullet.name"
                  v-for="(bullet, index) in data.bullets"
                  class="bullet__list__item"
                >
                  <img
                    width="20px"
                    height="20px"
                    :alt="'bullet-' + index"
                    class="bullet__list__item__text"
                    src="@/assets/icons/check-green.png"
                  />
                  <div
                    class="bullet__list__item__text text-left"
                    :class="
                      data.templateType == 'NoSidebar' ||  data.templateType == 'NoSidebarNarrowCanvas'
                        ? 'bullet-name'
                        : 'bullet-name2'
                    "
                  >
                    {{ bullet.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
         <no-ssr>
          <!-- ORDER SUMMARY -->
          <template v-if="displayDiv(posName, 'orderSummary')">
            <div
              v-if="
                !embed &&
                data.orderSummary &&
                payload &&
                data.themeType == 'Classic' &&
                data.templateType != 'NoSidebar' &&
                data.templateType != 'NoSidebarNarrowCanvas'
              "
              class="checkout-content--left__feature pp-order-summary pp-order-summary--bg-white"
            >
              <h2 class="pp-order-summary__title" style="font-size: 12px;">{{ $t('common.orderDetails') }}</h2>
              <!-- START ORDER SUMMARY LIST -->
              <div class="pp-order-summary__list">
                <!-- PRODUCT -->
                <div
                  v-if="data.type == 'simple'"
                  class="pp-order-summary__list__item"
                >
                  <div
                    class="common-font product-name product-title-shrink-checkout"
                  >
                    {{ data.name }} 
                    <span >({{data.enableProductOptions && data.addQuantity && cartQuantity >= 1
                        ? cartQuantity
                        : quantity
                      }}x)
                    </span>
                  </div>

                  <div
                    v-if="data.type == 'simple'"
                    class="text-right justify-between"
                  >
                    <div class="common-font regular_price">
                      Rp{{ price | g_number_format }}
                    </div>
                    <div
                      class="common-font sale_price text-danger left"
                      style="text-decoration: line-through"
                      v-if="data.priceCompare && data.type == 'simple'"
                    >
                      Rp{{ (quantity * data.price) | g_number_format }}
                    </div>
                    <div
                      v-else-if="
                        parseInt(priceWithoutWhosale) !== parseInt(price)
                      "
                      class="text-danger def common-font sale_price"
                      style="text-decoration: line-through"
                    >
                      Rp{{ priceWithoutWhosale | g_number_format }}
                    </div>
                  </div>
                </div>
                
                <!-- VARIANT ORDER -->
                <div
                  v-if="data.type != 'simple'"
                  class="pp-order-summary__list__item pp-order-summary__list__item--variant-order"
                >
                  <div class="common-font product-name product-title-shrink-checkout nosidebar">
                    {{ data.name }}
                    <span v-if="totalQtyMultipleVariant > 0">({{ totalQtyMultipleVariant }}x)</span>
                  </div>
                  <!-- VARIANT ORDER SINGLE -->
                  <template
                    v-if="
                      !data.multiplyVariations ||
                      data.type == 'simple' ||
                      (data.multiplyVariations && data.cartOrder)
                    "
                  >
                    <template
                      v-if="
                        cartSummary &&
                        cartSummary.length > 0 &&
                        data.multiplyVariations
                      "
                    >
                      <template v-for="(elem, i) in cartSummary">
                        <div
                          v-if="elem.qty"
                          v-bind:key="i"
                          class="item--variant-order__cart--qty"
                        >
                          <div
                            class="item--variant-order__elem-qty__info"
                          >
                            <!-- PRODUCT QTY AND VARIANT NAME -->
                            <div class="flex justify-start" style="flex: 1 1 auto;">
                              <div style="padding-right: 5px">
                                ({{
                                  cartSummary[i]
                                    ? cartSummary[i].qty
                                    : elem.qty
                                }}x)
                              </div>
                              <div class="single-variant">
                                <div
                                  v-for="(elemChild, j) in elem.items"
                                  v-bind:key="j + 'p'"
                                >
                                  {{ j.substring(0, j.length - 1) }}
                                  <span style="font-weight: 600;">{{ elemChild.name }}</span>
                                </div>
                              </div>
                            </div>

                            <!-- PRODUCT PRICE -->
                            <div v-if="elem.price == 0">
                              Rp{{ (elem.priceOriginal * elem.qty) | g_number_format }}
                            </div>
                            <div v-if="elem.priceOriginal && elem.price > 0">
                              <div style="text-align: end">
                                <div>
                                  Rp{{ getSubPrice(elem) | g_number_format }}
                                </div>
                                <div
                                  v-if="
                                    getSubPrice(elem) !==
                                    elem.priceOriginal * elem.qty
                                  "
                                  class="text-danger"
                                  style="text-decoration: line-through"
                                >
                                  Rp{{ (elem.priceOriginal * elem.qty) | g_number_format }}
                                </div>
                              </div>
                            </div>

                            <!-- VARIANT DELETE BUTTON -->
                            <div
                              v-if="cartSummary.length > 1"
                              class="delete-variant text-right ml-5px removeFromCart"
                            >
                              <img
                                @click="removeFromCartByName(elem.name)"
                                src="@/assets/icons/Delete-grey.svg"
                                alt=""
                                class="pointer"
                              />
                            </div>
                            
                          </div>
                        </div>
                      </template>
                    </template>

                    <div
                      class="item--variant-order__cart--default"
                      v-else
                    >
                      <div class="flex justify-start">
                        <div style="padding-right: 5px">
                          ({{
                            data.addQuantity && cartQuantity >= 1
                              ? cartQuantity
                              : quantity
                          }}x)
                        </div>
                        <div class="single-variant left">
                          <div
                            :key="l + 'p'"
                            v-for="(vprice, l) in Object.keys(
                              payload.variablePrices
                            )"
                          >
                            {{ vprice.substring(0, vprice.length - 1) }}
                            <span style="font-weight: 600;">{{ payload.variablePrices[vprice].name }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="text-right w-50 ct-1">
                        <div>Rp{{ price | g_number_format }}</div>
                        <div
                          class="text-danger"
                          style="text-decoration: line-through"
                          v-if="parseInt(priceWithoutWhosale) !== parseInt(price)"
                        >
                          Rp{{ priceWithoutWhosale | g_number_format }}
                        </div>
                      </div>
                    </div>
                  </template>

                  <!-- VARIANT ORDER MULTIPLY VARIATION -->
                  <template v-else>
                    <template>
                      <template
                        v-for="(attr, i) in data.variablePrices"
                      >
                        <div
                          v-if="attr.qty > 0"
                          :key="'f' + i"
                          class="item--variant-order__cart--qty"
                        >
                          <div class="item--variant-order__elem-qty__info">
                            <div class="flex justify-start" style="flex: 1 1 auto;">
                              <div style="padding-right: 5px">
                                ({{ attr.qty }}x)
                              </div>
                              <div class="single-variant">
                                <div 
                                  v-for="(elemChild, j) in attr.attrs"
                                  v-bind:key="elemChild.name + elemChild.value + j"
                                >
                                  {{elemChild.name.substring(0, elemChild.name.length - 1) }}
                                  <span style="font-weight: 600;">{{elemChild.value }}</span>
                                </div>
                              </div>
                            </div>
                            <div style="text-align: end">
                              <div>
                                Rp{{ getSubPrice(attr) | g_number_format }}
                              </div>
                              <div
                                v-if="getSubPrice(attr) !== attr.price * attr.qty"
                                class="text-danger"
                                style="text-decoration: line-through"
                              >
                                Rp{{ (attr.price * attr.qty) | g_number_format }}
                              </div>
                            </div>
                            <div
                              v-if="attr.qty > 0"
                              class="delete-variant text-right removeFromCart ml-5px"
                            >
                              <img
                                @click="deleteVariant(i)"
                                src="@/assets/icons/Delete-grey.svg"
                                alt=""
                                class="pointer"
                              />
                            </div>
                          </div>
                        </div>
                       
                      </template>
                    </template>
                  </template>

                </div>
                <!-- BUMP -->
                <div
                  v-if="data.isBump && selectedbumpArray.length > 0"
                  class="pp-order-summary__list__item pp-order-summary__list__item--bump"
                  style="border-bottom-style: solid;"
                >
                  <div class="item--bump__label">{{ $t('common.bumpProduct') }}</div>
                  <div class="item--bump__list">
                    <div
                      v-for="(data, i) in selectedbumpArray"
                      :key="i"
                      class="item--bump__list__product"
                    >
                      <div>{{ data.bumpName }}</div>
                      <div class="flex items-center gap-10px flexshrink">
                        <span>Rp{{ data.bumpPrice | g_number_format }}</span>
                        <div
                          @click="deleteBumpProduct(i)"
                          role="button"
                          class="delete-variant text-right removeFromCart ml-10 flexshrink"
                        >
                          <img
                            src="@/assets/icons/Delete-grey.svg"
                            alt=""
                            class="pointer"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- SHIPPING COST AUTOMATES -->
                <div
                  v-if="
                    data.addShipping &&
                    (payload.payment === 'COD' ? data.shippingCOD.shippingType === 'automated' : data.shippingType === 'automated') &&
                    parseInt(payload.deliveryPrice) +
                      parseInt(payload.payment === 'COD' ? data.shippingCOD.hideAdditionalCost ? data.shippingCOD.additionalCost : 0 : data.additionalCost) >
                      0
                  "
                  class="pp-order-summary__list__item shippingCosts"
                  style="border-bottom-style: solid;"
                >
                  <div>{{ $t('common.shippingCosts') }} ({{ weight | gramsToKilograms_format }}kg)</div>
                  <div v-if="payload.deliveryPrice">
                    Rp{{ parseInt(payload.deliveryPrice) + parseInt(payload.payment === 'COD' ? (data.shippingCOD.hideAdditionalCost ? data.shippingCOD.additionalCost : 0) : (data.hideAdditionalCost ? data.additionalCost : 0)) | g_number_format }}
                  </div>
                  <div v-else>
                    {{ parseInt(0) | g_number_format }}
                  </div>
                </div>
                <div v-if="(payload.payment === 'COD' ? data.shippingCOD.shippingType === 'automated' : data.shippingType === 'automated') &&
                data.addShipping && 
                (payload.payment === 'COD' ? data.shippingCOD.shippingDiscount : data.shippingDiscount) && 
                (payload.payment === 'COD' ? data.shippingCOD.shippingDiscount.status : data.shippingDiscount.status) &&
                  parseInt(shippingDiscountValue) >
                  0
                  " class="pp-order-summary__list__item"
                  style="border-bottom-style: solid;">
                  <div>Diskon Pengiriman</div>
                  <div>
                    -Rp{{
                      parseInt(shippingDiscountValue)
                      | g_number_format
                    }}
                  </div>
                </div>

                <!-- ADDTIONAL SHIPPING COST -->
                <div
                  class="pp-order-summary__list__item"
                  style="border-bottom-style: solid;"
                  v-if="(payload.payment === 'COD' ? data.shippingCOD.shippingType === 'automated' : data.shippingType === 'automated') &&
                  data.addShipping && (payload.payment === 'COD' ? data.shippingCOD.additionalCost : data.additionalCost) &&
                  parseInt(payload.payment === 'COD' ? data.shippingCOD.additionalCost : data.additionalCost) != 0 &&
                  (payload.payment === 'COD' ? !data.shippingCOD.hideAdditionalCost : !data.hideAdditionalCost)
                  "
                >
                  <div>{{ $t('common.additionalShippingCost') }}</div>
                  <div>
                    Rp{{ parseInt(payload.payment === 'COD' ? data.shippingCOD.additionalCost : data.additionalCost) | g_number_format }}
                  </div>
                </div>

                <!-- SHIPPING COST FLAT -->
                <div
                  v-if="
                    data.addShipping &&
                    (payload.payment === 'COD' ? data.shippingCOD.shippingType === 'flat' : data.shippingType === 'flat')
                  "
                  class="pp-order-summary__list__item"
                  style="border-bottom-style: solid;"
                >
                  <div>{{ $t('common.shippingCosts') }}</div>
                  <div>
                    Rp{{
                      parseInt(
                        (payload.payment === 'COD' ? data.shippingCOD.flatShippingPrice : data.flatShippingPrice) ?
                        (payload.payment === 'COD' ? data.shippingCOD.flatShippingPrice : data.flatShippingPrice) : 0
                      ) | g_number_format
                    }}
                  </div>
                </div>

                <no-ssr>
                <!-- COD COST -->
                <div
                  v-if="payload.payment == 'COD'"
                  class="pp-order-summary__list__item flex justify-between"
                  style="border-bottom-style: solid;"
                >
                  <div>{{ $t('common.codFee') }}</div>
                  <div>Rp{{ codPrice | g_number_format }}</div>
                </div>

                <!-- EPAY FEE -->
                <div
                  v-if="
                    this.payload.payment &&
                    this.payload.payment != 'bankTransfer' &&
                    this.payload.payment != 'COD' &&
                    data.ePaymentFeePaidBy === 'buyer'
                  "
                  class="pp-order-summary__list__item flex justify-between"
                  style="border-bottom-style: solid;"
                >
                  <div>
                    {{ paymentFeeMethodName(this.payload.payment) }}
                    <p class="m-0 e-payment-admin-fee" v-if="this.payload.payment === 'gopay'">{{ $t('settings.adminEPayFeeGopay') }}</p>
                    <p class="m-0 e-payment-admin-fee" v-else-if="this.payload.payment === 'QRIS'">{{ $t('settings.adminEPayFeeQRIS') }}</p>
                    <p class="m-0 e-payment-admin-fee" v-else-if="this.payload.payment === 'credit_card'">{{ $t('settings.adminEPayFeeCreditCard') }}</p>
                    <p class="m-0 e-payment-admin-fee" v-else>{{ $t('settings.adminEPayFeeOther') }}</p>
                  </div>
                  <div>Rp{{ calEpayFee() | g_number_format }}</div>
                </div>
                </no-ssr>

                <!-- <div v-if="totalBumpOrderPrice && data.isBump" class="flex justify-between">
                  <div>Bump Price</div>
                  <div>Rp{{ totalBumpOrderPrice | g_number_format }}</div>
                </div> -->

                <!-- UNIQUE PRICE -->
                <div
                  v-if="
                    payload.uniquePrice && payload.payment == 'bankTransfer'
                  "
                  class="pp-order-summary__list__item"
                  style="border-bottom-style: solid;"
                >
                  <div>{{ $t('common.uniquePrice') }}</div>
                  <div>
                    Rp {{ this.payload.uniqueType == "Decrease" ? "-" : "" }}
                    {{ payload.uniquePrice }}
                  </div>
                </div>               
                <!-- COUPON -->               
                <div v-if="payload.discount && payload.discount.code" class="pp-order-summary__list__item" style="border-bottom-style: solid;">
                  <div>{{payload.discount.code}}</div>         
              
                  <div>-Rp{{ payload.discount.amount | g_number_format }}</div>
                </div>
                <div v-if="data.showCoupon && payload.automaticDiscount && payload.automaticDiscount.code" class="pp-order-summary__list__item" style="border-bottom-style: solid;">
                  <div>{{payload.automaticDiscount.code}}</div>        
              
                  <div>-Rp{{ payload.automaticDiscount.amount | g_number_format }}</div>
                </div>
                <!-- VAT (PPN) -->
                <div v-if="data.VAT && data.VAT.isVAT && data.VAT.VATpercentage > 0" class="vat-price flex justify-between align-items-center">
                  <div>PPN {{ data.VAT.VATpercentage }}%</div>
                  <div>Rp{{ (cartSummary.length > 0 ? Math.round((cartTotalPrice * this.data.VAT.VATpercentage)/100) : VATPrice) | g_number_format }}</div>
                </div>
                
              </div>
              <!-- END ORDER SUMMARY LIST -->

              <!-- TOTAL -->
              <div class="pp-order-summary__total" style="border-top-style: solid;">
                <p class="pp-order-summary__total__label">{{ $t('common.total') }}</p>
                <p class="pp-order-summary__total__number">   
                  <no-ssr>          
                  <template v-if="data.multiplyVariations">
                    <template
                      v-if="
                        this.payload.payment &&
                        this.payload.payment != 'bankTransfer' &&
                        this.payload.payment != 'COD'
                      "
                    >
                      Rp{{ totalwithEpayFee() | g_number_format }}
                    </template>
                    <template v-else>
                      Rp{{
                        (cartSummary.length > 0 && cartTotalPrice
                          ? Math.round(cartTotalPrice + (data.VAT && data.VAT.isVAT ? (cartTotalPrice * data.VAT.VATpercentage)/100 : 0 ))
                          : totalPrice) | g_number_format
                      }}
                    </template>
                  </template>
                  <template v-else>
                    <template
                      v-if="
                        this.payload.payment &&
                        this.payload.payment != 'bankTransfer' &&
                        this.payload.payment != 'COD'
                      "
                    >
                      Rp{{ totalwithEpayFee() | g_number_format }}
                    </template>
                    <template v-else
                      >Rp{{ totalPrice | g_number_format }}</template
                    >
                  </template>
                </no-ssr>  
                </p>
              </div>
            </div>
          </template>
        </no-ssr>
        </div>
        <no-ssr>
        <!-- ORDER SUMMARY ON MOBILE DEVICE-->
        <template v-if="displayDiv(posName, 'orderSummary')">
          <div
            v-if="data.themeType != 'Classic' && data.orderSummary"
            class="checkout-content--left__feature embed-checkout-left-summary borderless"
          >
            <div class="pricing-container">
              <!-- Price -->
              <div
                v-if="!data.multiplyVariations || data.type == 'simple'"
                class="flex justify-between"
              >
                <div>{{ $t('common.price') }}</div>
                <div class="text-right">
                  <div>Rp{{ price | g_number_format }}</div>
                  <div
                    class="text-danger"
                    style="text-decoration: line-through"
                    v-if="data.priceCompare && data.type == 'simple'"
                  >
                    Rp{{ (quantity * data.priceCompare) | g_number_format }}
                  </div>
                  <div
                    v-else-if="
                      parseInt(priceWithoutWhosale) !== parseInt(price)
                    "
                    class="text-danger"
                    style="text-decoration: line-through"
                  >
                    Rp{{ priceWithoutWhosale | g_number_format }}
                  </div>
                  
                </div>
              </div>

              <hr
                v-if="
                  !data.multiplyVariations ||
                  data.type == 'simple' ||
                  (data.multiplyVariations && data.cartOrder)
                "
                class="mt-2.5 mb-2.5"
              />

              <div v-if="data.type != 'simple'">
                <template
                  v-if="
                    !data.multiplyVariations ||
                    data.type == 'simple' ||
                    (data.multiplyVariations && data.cartOrder)
                  "
                >
                  <template
                    v-if="
                      cartSummary &&
                      cartSummary.length > 0 &&
                      data.multiplyVariations
                    "
                  >
                    <template v-for="(elem, i) in data.variablePrices.filter(x=>!x.delete)">
                      <div
                        v-if="elem.qty"
                        class="order-summary-list"
                        v-bind:key="i"
                      >
                        <div class="flex justify-between">
                          <div class="flex justify-start">
                            <div style="font-size: 12px; padding-right: 5px">
                              ({{ elem.qty }}x)
                            </div>
                            <div class="single-variant">
                              <div
                                :key="l + 'p'"
                                v-for="(vprice, l) in Object.keys(elem.attrs)"
                                style="font-size: 12px"
                              >
                                {{ elem.attrs }}
                                {{
                                  elem.attrs[vprice].name.substring(
                                    0,
                                    elem.attrs[vprice].name.length - 1
                                  )
                                }} : 
                                {{ elem.attrs[vprice].value }}
                              </div>
                            </div>
                          </div>
                          <div style="text-align: end">
                            <div style="font-size: 12px">
                              Rp{{ getSubPrice(elem) | g_number_format }}
                            </div>
                            <div
                              class="text-danger"
                              style="
                                text-decoration: line-through;
                                font-size: 12px;
                              "
                              v-if="getSubPrice(elem) !== elem.price * elem.qty"
                            >
                              Rp{{ (elem.price * elem.qty) | g_number_format }}
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="elem.qty > 0"
                          class="delete-variant text-right deleteVariant"
                          
                        >
                          <img class="pointer" @click="deleteVariant(i)" src="@/assets/icons/Delete-grey.svg" alt="" />
                        </div>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div
                      :key="l + 'p'"
                      v-for="(vprice, l) in Object.keys(payload.variablePrices)"
                    >
                      {{ vprice.substring(0, vprice.length - 1) }}:
                      {{ payload.variablePrices[vprice].name }}
                    </div>
                  </template>
                </template>
                <div v-else>
                  <template v-for="(attr, i) in data.variablePrices.filter(x=>!x.delete)">
                    <div
                      v-if="attr.qty > 0"
                      :key="'f' + i"
                      class="flex justify-between mb-1 mt-3"
                      style="
                        padding-bottom: 4px;
                        border-bottom: 1px dashed #ccc;
                      "
                    >
                      <div class="text-left w-50">
                        {{
                          attr.attrs
                            .map(
                              (n) =>
                                n.name.substring(0, n.name.length - 1) +
                                ": " +
                                n.value
                            )
                            .join(",")
                        }}
                      </div>
                      <div>Rp{{ getSubPrice(attr) | g_number_format }}</div>
                      <div
                        class="text-danger"
                        style="text-decoration: line-through"
                        v-if="getSubPrice(attr) !== attr.price * attr.qty"
                      >
                        Rp{{ (attr.price * attr.qty) | g_number_format }}
                      </div>
                      <div
                        v-if="attr.qty > 0"
                        class="delete-variant text-right"
                        
                      >
                        <img class="pointer" @click="deleteVariant(i)" src="@/assets/icons/Delete-grey.svg" alt="" />
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <!-- SUBTOTAL -->
              <div
                class="flex justify-between mb-2 mt-2"
                v-if="cartSummary.length == 0 && !data.multiplyVariations"
              >
                <div v-if="data.name" class="summary-label">
                  {{ data.name }}
                </div>
                <div class="total price">Rp{{ price | g_number_format }}</div>
              </div>

              <hr class="mt-2.5 mb-2.5" />

              <!-- SHIPPING  -->
              <div
                v-if="
                  (data.addShipping && (payload.payment === 'COD' ? data.shippingCOD.shippingType === 'automated' : data.shippingType === 'automated')) ||
                  (payload.payment === 'COD' ? data.shippingCOD.shippingType === 'flat' : data.shippingType === 'flat')
                "
                class="flex justify-between mb-2 mt-2 summary-label"
              >
                <div>{{ $t('common.shippingPlusCodFee') }}</div>
                <div v-if="(payload.payment === 'COD' ? data.shippingCOD.shippingType === 'automated' : data.shippingType === 'automated')">
                  Rp{{
                    (parseInt(payload.deliveryPrice) +
                      parseInt((payload.payment === 'COD' ? data.shippingCOD.additionalCost : data.additionalCost)) +
                      parseInt(codPrice))
                      | g_number_format
                  }}
                </div>
                <div v-if="(payload.payment === 'COD' ? data.shippingCOD.shippingType === 'flat' : data.shippingType === 'flat')">
                  Rp{{
                    (parseInt((payload.payment === 'COD' ? data.shippingCOD.flatShippingPrice : data.flatShippingPrice)) +
                      parseInt((payload.payment === 'COD' ? data.shippingCOD.additionalCost : data.additionalCost)) +
                      parseInt(codPrice))
                      | g_number_format
                  }}
                </div>
              </div>

              <!-- BUMP PRICE  -->
              <!-- <div v-if="totalBumpOrderPrice && data.isBump" class="flex justify-between mb-2 mt-2 summary-label">
                <div>Bump Price</div>
                <div>Rp{{ totalBumpOrderPrice | g_number_format }}</div>
              </div> -->

              <!-- <hr v-if="payload.bump" class="mt-2.5 mb-2.5" /> -->
              <div
                v-if="payload.uniquePrice && payload.payment == 'bankTransfer'"
                class="flex justify-between"
              >
                <div>{{ $t('common.uniquePrice') }}</div>
                <div>
                  {{ this.payload.uniqueType == "Decrease" ? "-" : "" }}
                  {{ payload.uniquePrice }}
                </div>
              </div>

              <hr class="mt-2.5 mb-2.5" />
              <!-- TOTAL  -->
              <div class="flex justify-between font-bold">
                <div class="summary-total">{{ $t('common.total') }}</div>
                <div
                  v-if="data.multiplyVariations"
                  class="common-font total price"
                >
                  <div
                    v-if="
                      this.payload.payment &&
                      this.payload.payment != 'bankTransfer' &&
                      this.payload.payment != 'COD'
                    "
                    class="common-font total price"
                  >
                    Rp{{ totalwithEpayFee() }}
                  </div>
                  <div v-else>
                    Rp{{
                      (cartSummary.length > 0 ? cartTotalPrice : totalPrice)
                        | g_number_format
                    }}
                  </div>
                </div>
                <div v-else>
                  <div class="total price">
                    <div
                      v-if="
                        this.payload.payment &&
                        this.payload.payment != 'bankTransfer' &&
                        this.payload.payment != 'COD'
                      "
                      class="common-font total price"
                    >
                      Rp{{ totalwithEpayFee() }}
                    </div>
                    <div v-else>Rp{{ totalPrice | g_number_format }}</div>
                  </div>
                </div>
              </div>
              <div class="summary-tax">{{ $t('common.includeTax') }}</div>
            </div>
          </div>
        </template>
      </no-ssr>
        <template v-if="displayCopyright(posIndex)">
          <div
            v-if="data.themeType != 'Classic'"
            class="checkout-content--left__feature embed-checkout-left-testimonial"
          >
            <Bottom :data="data" />
          </div>
        </template>
      </div>

    </div>

    <!-- Button-->
    <template v-if="displayDiv(posName, 'buyButton')">
      <!--- Captcha -->
      <div
        v-if="
          data.themeType != 'Classic' &&
          data.templateType == 'RightSidebar' &&
          isSizeWindow &&
          showCaptcha
        "
        class="flex justify-start"
      >
        <vue-hcaptcha
          :sitekey="googleCaptchaKey"
          @verify="verifyCaptcha"
          @expired="expiredMethod"
          @challenge-expired="expiredMethod"
        ></vue-hcaptcha>
      </div>

      <div
        class="embed-buyButton flex align-items-center grid-gap15px"
        style="
          min-height: 55px;
          border-radius: 8px;
          background-color: rgb(21, 153, 239);
          display: block;
        "
        v-if="
          data.themeType != 'Classic' &&
          data.templateType == 'RightSidebar' &&
          isSizeWindow
        "
      >
        <div
          v-if="isSizeWindow && cartSummary.length && data.cartOrder"
          @click="openCartSummary()"
          style="
            flex: 0 0 56px;
            width: 56px;
            background: #ffffff;
            border: 1px solid #bfcee0;
            border-radius: 8px;
            max-height: 56px;
            height: 100%;
            padding: 17px;
          "
        >
          <div class="flex align-items-center position-relative">
            <div class="cart-counter">
              {{ cartSummary ? cartSummary.length : 0 }}
            </div>
            <img src="@/assets/icons/Cart-bucket.svg" alt="" />
          </div>
        </div>
        <div
          :class="
            isSizeWindow && cartSummary.length && data.cartOrder
              ? 'flex: 0 0 calc(100% - 70px); width: calc(100% - 70px);'
              : 'flex: 0 0 100%; width: 100%;'
          "
        >
          <div
            v-if="isDisabled"
            style="border-radius: 5px; background-color: #e0e0e0"
            class="w-100 px-2 py-1 text-white text-cente"
          >
            <div
              style="color: rgba(0, 0, 0, 0.26)"
              class="flex items-center justify-center"
            >
              {{ $t('common.stockEmpty') }}
            </div>
          </div>
          <div
            v-else-if="showCaptcha && !verifiedCaptcha"
            style="border-radius: 5px; background-color: #e0e0e0"
            class="w-100 px-2 py-1 text-white text-cente"
          >
            <div
              style="color: rgba(0, 0, 0, 0.26); min-height: 50px"
              class="flex items-center justify-center"
            >
              {{ buyButton.buyButtonText }}
            </div>
          </div>
          <div
            v-else
            @click="() => !loadingPrice && save()"
            class="flex justify-center items-center w-100 text-white text-center pointer"
            style="min-height: 55px; border-radius: 8px"
            :style="
              'background-color: ' +
              data[`buyButtonStyle${this.uniqueId}`] +
              (loadingPrice ? '; opacity: 0.5;' : '')
            "
          >
            <div>
              <span
                :class="{
                  'bold-buy-btn': buyButton.boldBuyButtonText,
                  'small-buy-btn': buyButton.buyButtonFontSize == 'Small',
                  'medium-buy-btn': buyButton.buyButtonFontSize == 'Medium',
                  'large-buy-btn': buyButton.buyButtonFontSize == 'Large',
                  'extra-large-buy-btn':
                    buyButton.buyButtonFontSize == 'Extra-Large',
                }"
              >
                {{ buyButton.buyButtonText }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </template>
 
  </div>
</template>

<script>
import config from "./../../config.js";
// import { defineAsyncComponent } from "vue";
// const Seal = defineAsyncComponent(() => import("./seal.vue"));
// const Bottom = defineAsyncComponent(() => import("./bottom.vue"));
// const Carousel = defineAsyncComponent(() => import("../Carousel.vue"));

import Seal from './seal.vue';
import Bottom from "./bottom.vue";
import ProductImage from "@/components/ProductImage";
import { getSizedImageUrl } from "@/helpers/imagekitHelper";
import { paymentFeeMethodName } from "@/helpers/epayMethodHelper";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import axios from "axios";

export default {
  props: [
    "data",
    "form",
    "weight",
    "payload",
    "price",
    "totalPrice",
    "quantity",
    "priceWithoutWhosale",
    "codPrice",
    "isSizeWindow",
    "loadingPrice",
    "cartSummary",
    "embed",
    "popup",
    "cartQuantity",
    "cartTotal",
    "showCaptcha",
    // "totalBumpOrderPrice",
    "selectedbumpArray",
    "posName",
    "posIndex",
    "pos",
    "errors",
    "validate",
    "submited",
    "shippingDiscountValue",
    "productImages",
    "variantImageIndex",
    "VATPrice"
  ],
  components: {
    Seal: Seal,
    Bottom: Bottom,
    VueHcaptcha,
    ProductImage,
  },
  data() {
    return {
      isMobile: process.client ? window.innerWidth < 575 : false,
      baseUrl: config.api.replace("/api", ""),
      googleCaptchaKey: config.googleCaptchaKey,
      videoUrl: "",
      isHidden: false,
      verifiedCaptcha: false,
      buyButton: {},
      getSizedImageUrl,
      paymentFeeMethodName
    };
  },
  created() {
    if (this.pos.id && this.pos.id > 1) {
      this.buyButton = this.data.buyButtonChild[this.pos.uniqueName];
    } else {
      this.buyButton = this.data;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 575;
    },
    addContainerClass(param) {
      if (this.isSizeWindow) {
        if (param == "guaranteeSeal") {
          if (this.data.seals) {
            return true;
          }
        } else if (param == "productImage") {
          if (this.productImages.length) {
            return true;
          }
        } else if (param == "content") {
          if (this.data.content) {
            return true;
          }
        } else if (param == "content_bullets") {
          if (this.data.content || this.data.bullets) {
            return true;
          }
        }
      } else {
        if (
          (this.data.seals && this.data.showSeals) ||
          (this.data.showProductImages && this.productImages.length) ||
          (this.data.showContentBullet && this.data.content) ||
          (this.data.showContentBullet && this.data.bullets) ||
          this.data.orderSummary
        ) {
          return true;
        }
      }
      return false;
    },
    displayCopyright(index) {
      if (this.isSizeWindow) {
        if (this.compfieldsPositionMobile.length - 1 == index) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    displayDiv(fieldName, divName) {
      if (this.isSizeWindow) {
        if (fieldName == divName) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    async verifyCaptcha(data) {
      let res = await axios.post(config.api + "auth/verifyGoogleCaptcha", {
        response: data,
      });
      if (res.data.data.success) {
        this.verifiedCaptcha = true;
      }
    },

    expiredMethod() {
      this.verifiedCaptcha = false;
    },

    totalwithEpayFee() {
      let pricewithEpay = 0;
      let total =
        this.cartSummary.length > 0 ? this.cartTotal : this.totalPrice;
      if (
        this.payload.payment &&
        this.payload.payment != "bankTransfer" &&
        this.payload.payment != "COD"
      ) {
        let transactionFeeGopay = (total * 2) / 100;
        let transactionFeeCreditCard = 4000 + (this.totalPrice * 11) / 100;
        let transactionFeeQuris = (total * 0.7) / 100;
        let transactionFeeVirtualAccount = 4000;

        pricewithEpay = Math.round(total);

        if (this.data.ePaymentFeePaidBy === 'buyer') {
          pricewithEpay = Math.round(
            this.payload.payment == "gopay"
              ? total + transactionFeeGopay
              : this.payload.payment == "credit_card"
              ? total + transactionFeeCreditCard
              : this.payload.payment == "QRIS"
              ? total + transactionFeeQuris
              : (this.payload.payment == "bca_va" || this.payload.payment == "bni_va" || this.payload.payment == "echannel" || this.payload.payment == "bri_va") ? total + transactionFeeVirtualAccount : total
          );
        }
      }
      return pricewithEpay;
    },
    calEpayFee() {
      let fee = 0;
      if (this.data && this.data.ePaymentFeePaidBy === 'buyer') {
        if(this.payload && this.payload.payment){
          if (this.payload.payment == "bca_va" || this.payload.payment == "bni_va" || this.payload.payment == "echannel" || this.payload.payment == "bri_va") {
            fee = 4000;
          }
          if (this.payload.payment == "gopay") {
            fee = Math.round((this.totalPrice * 2) / 100);
          }
          if (this.payload.payment == "credit_card") {
            fee = Math.round(4000 + (this.totalPrice * 11) / 100);
          }
          if (this.payload.payment == "QRIS") {
            fee = Math.round((this.totalPrice * 0.7) / 100);
          }
        }
      }
      return fee;
    },
    calcCart() {
      const isPaymentCOD = this.payload.payment === 'COD';
      const shippingType = isPaymentCOD ? this.data.shippingCOD.shippingType : this.data.shippingType;
      const additionalCost = isPaymentCOD ? this.data.shippingCOD.additionalCost : this.data.additionalCost;
      const flatShippingPrice = isPaymentCOD ? this.data.shippingCOD.flatShippingPrice : this.data.flatShippingPrice;
      
      // this.totalBumpOrderPrice = this.totalBumpOrderPrice ? this.totalBumpOrderPrice : 0
      let price =
        0 +
        parseFloat(this.payload.deliveryPrice ? this.payload.deliveryPrice : 0);
      this.cartSummary.map(async (e) => {
        let subPrice = (e.price ? e.price : e.priceOriginal) * e.qty;
        if (e.whosaleData) {
          e.whosaleData.map((one) => {
            if (one.count && parseInt(one.count) <= parseInt(e.qty)) {
              subPrice = one.price * parseInt(e.qty);
            }
            return subPrice;
          });
        }
        price = price + subPrice;
      });
      if (isPaymentCOD) {
        price = price + parseFloat(this.codPrice);
      }
      if (this.payload.uniquePrice && this.payload.payment == "bankTransfer") {
        price =
          price +
          (this.data.codeType == "Decrease"
            ? -this.payload.uniquePrice
            : this.payload.uniquePrice);
      }
      if (this.data.addShipping && additionalCost) {
        price = price + parseInt(additionalCost);
      }
      if (flatShippingPrice && shippingType == "flat") {
        price = price + parseInt(flatShippingPrice);
      }
      
      if (this.shippingDiscountValue && this.data.addShipping && shippingType == "automated" && this.shippingDiscountValue > 0) {
        price = price - parseFloat(parseFloat(this.shippingDiscountValue));
      }

      // if (this.payload.bump) {
      //   price = price + parseFloat(this.data.bumpPrice)
      // }

      if (
        this.data.isBump &&
        this.data.bumpArray &&
        this.data.bumpArray.length >= 0 &&
        this.selectedbumpArray
      ) {
        // price = price + parseFloat(this.data.bumpPrice)
        this.selectedbumpArray.map((bumpData) => {
          //  const bumpData = this.data.bumpArray.find((data) => data.bump_id == bump_id)
          if (bumpData) {
            price =
              price + (bumpData.bumpPrice ? parseFloat(bumpData.bumpPrice) : 0);
            //  this.totalBumpOrderPrice = this.totalBumpOrderPrice + parseFloat(bumpData.bumpPrice)
          }
        });
      }
      if (typeof price == "string") {
        price = parseFloat(price);
      }      
     
      if(this.payload.discount && this.payload.discount["amount"] > 0){
        price = price-this.payload.discount["amount"]
      }    
      if(this.payload.automaticDiscount && this.payload.automaticDiscount["amount"] > 0){
        price = price-this.payload.automaticDiscount["amount"]
      }      
      // return parseInt(price.toFixed())
      this.cartTotalPrice = parseInt(price.toFixed());
      
    },
    openCartSummary() {
      this.$emit("cartOrderSummary");
    },
    deleteVariant(index) {
      if (this.data.variablePrices) {
        this.data.variablePrices[index].qty = 0;
      }
      this.$forceUpdate();
    },
    deleteBumpProduct(index) {
      this.selectedbumpArray.splice(index, 1)
    },
    removeFromCart(index) {
      if (this.cartSummary.length) {
        this.$emit("updateCartSummary", index);
      } else {
        if (this.data.variablePrices) {
          this.data.variablePrices[index].qty = 0;
        }
      }
      this.$forceUpdate();
    },

    removeFromCartByName(name) {
      const obj = this.cartSummary.find(
        (data) => data.name.toString() == name.toString()
      );
      const index = this.cartSummary.indexOf(obj);
      if (index === -1) {
        return;
      }
      if (this.cartSummary.length) {
        this.$emit("updateCartSummary", index);
      } else {
        if (this.data.variablePrices) {
          this.data.variablePrices[index].qty = 0;
        }
      }
      this.calcCart();
      this.$forceUpdate();
    },
    getSubPrice(attr) {
      let price = 0;
      if (attr.qty) {
        let subPrice = attr.priceCompare ? attr.priceCompare : attr.price;
        if (attr.whosaleData) {
          attr.whosaleData.map((one) => {
            if (one.count && parseInt(one.count) <= parseInt(attr.qty)) {
              subPrice = one.price;
            }
          });
        }
        price = price + subPrice * attr.qty;
      }
      return price;
    },
    getVideoId() {
      let val = this.data.componentVideo;
      // var regExp =
      //   /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      // if (val) {
      //   var match = val.match(regExp);
      //   this.videoId = match && match[7].length == 11 ? match[7] : false;
      // }    
      if (val !== "") {
        if (val.includes("https://youtu.be/")) {
          this.videoUrl =
            val.split("/youtu.be/").length > 1
              ? val.split("/youtu.be/")[1]
              : val;
        } else if (val.includes("?")) {
          this.videoUrl =
            val.split("v=").length > 1 ? val.split("v=")[1] : val;
        } else {
          this.videoUrl = val;
        }
      } else {
        this.videoUrl = val;
      }
    },
    save() {
      this.$emit("onSubmitted", true);
    },
    productImageZoom(e) {
      const zoomImage = e.currentTarget;
      const { offsetX, offsetY } = e;
      zoomImage.style.transformOrigin = `${offsetX}px ${offsetY}px`
      zoomImage.style.transform = `scale(2)`;
    },

    productImageZoomReset(e) {
      const zoomImage = e.currentTarget;
      zoomImage.style.transform = 'scale(1)';
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.getVideoId();
    this.calcCart();   
    
  },
  computed: {
    compfieldsPositionMobile() {
      return this.mobileVersion
        ? this.data.fieldsPositionMobile
        : ["singledummy"];
    },
    cartTotalPrice: {
      get() {
        return this.cartTotal;
      },
      set(val) {       
        this.$emit("updateCartTotal", val);
      },
    },
    isDisabled() {
      if (this.data.trackQuantity && !this.data.continueSelling) {
        if (this.data.type == "simple") {
          return this.data.available < this.payload.quantity;
        } else {
          let disabled = false;
          this.data.variablePrices.map((one) => {
            if (one.available < one.qty) {
              disabled = true;
            }
          });
          return disabled;
        }
      } else {
        return false;
      }
    },
    totalQtyMultipleVariant() {
      if (this.cartSummary && this.cartSummary.length > 0 && this.data.multiplyVariations) {
        return (this.cartSummary || []).reduce((accumulation, item) => {
          return (accumulation || 0) + (item.qty || 0)
        }, 0)
      }

      return (this.data.variablePrices || []).reduce((accumulation, item) => {
        return (accumulation || 0) + (item.qty || 0)
      }, 0)
    },
  },
  watch: {
    selectedbumpArray: {
      handler() {
        this.calcCart();
      },
      deep: true,
    },
    "data.variablePrices": {
      handler() {       
       // this.updateProductImages();
      },
      deep: true,
    },
    payload: {
      handler() {
        this.calcCart();
        this.$emit("updateCheckout");
        //this.updateProductImages();
      },
      deep: true,
    },
    "data.video"() {
      this.getVideoId();
    },
    "data.requestedFields": {
      handler() {
        this.$emit("updateCheckout");
        // this.validate();
        // this.$emit("onValidate", true);
      },
      deep: true,
    },
    "data.additionalCost": {
      handler() {
        this.calcCart();
      },
      deep: true,
    },
    "data.shippingCOD.additionalCost": {
      handler() {
        this.calcCart();
      },
      deep: true,
    },
    "payload.payment": {
      handler(val) {
        this.calcCart();
        this.paymentFeeMethodName(val)
      },
      deep: true,
    },
    "payload.deliveryPrice": {
      handler() {
        this.calcCart();
      },
      deep: true,
    },
    "payload.bump": {
      handler() {
        this.calcCart();
      },
      deep: true,
    },
  },
  filters: {
    gramsToKilograms_format(val) {
      return (val / 1000).toFixed(1);
    },
  },
};
</script>

<style scoped lang="scss">
.e-payment-admin-fee {
  font-size: 11px;
  font-weight: 500;
  color: #6C7E95;
  margin-top: 2px !important;
}
.single-variant{
  &>:not([hidden])~:not([hidden]) {
    margin-top: 3px !important;
    margin-bottom: 0px !important;
  }
}
.add-product-preview-right-youtube{
  width: 100%;
  max-width: 100%;
  iframe {
    width: 100%;
    border-radius: 8px;
    border: 0;
    aspect-ratio: 16/9;
  }
}
.after_bg {
  position: relative;
  padding: 15px 0px !important;
}

.after_bg::after {
  content: "";
  background: #f8f9fa;
  width: 111%;
  height: 100%;
  position: absolute;
  left: -20px;
  right: -20px;
  top: 0;
  z-index: -1;
}



.embed-productContent {
  position: relative;
  padding: 0 15px 0px !important;
  z-index: 0;
  margin-bottom: -16px;
}

.embed-productContent::after {
  content: "";
  background: #f8f9fa;
  width: 121%;
  height: 100px;
  position: absolute;
  left: -30px;
  right: -30px;
  top: -20px;
  z-index: -1;
}

.embed-checkout-left-summary {
  padding: 15px !important;
  border: 2px solid #2e47ba !important;
}

.embed-checkout-left-summary.borderless {
  padding: 0 !important;
  border: 0 !important;
}

.bold-buy-btn {
  font-weight: 700 !important;
}

.small-buy-btn {
  font-size: 20px;
  font-weight: 500;
}

.medium-buy-btn {
  font-size: 23px;
  font-weight: 500;
}

.large-buy-btn {
  font-size: 26px;
  font-weight: 500;
}

.extra-large-buy-btn {
  font-size: 29px;
  font-weight: 500;
}

.common-font.rightLeft-big-label {
  margin-bottom: 5px !important;
}
</style>
<style lang="scss">
@import "@/assets/styles/pages/checkout/components/form/left.scss";
</style>