<template>
  <div
    v-if="data.payload"
    class="theme--tuktuk__checkout__order-summary"
  >
    <!-- STORE NAME -->
    <!-- <div class="theme--tuktuk__checkout__order-summary__store">
      <div
        class="theme--tuktuk__checkout__order-summary__store__thumbnail"
      ></div>
      <h2 class="theme--tuktuk__title">LUCA BLIGHT STORE</h2>
    </div> -->

    <!-- START ORDER SUMMARY LIST -->
    <div class="theme--tuktuk__checkout__order-summary__list">     
      <div class="d-flex align-items-center justify-between">
        <h2 class="theme--tuktuk__title">{{ $t("tuktukTheme.order") }}</h2>
        <div role="button" class="select-options--tuktuk__button__right-side"  @click="openChooseVariantModal()">
          <span>
            {{ $t("tuktukTheme.change") }}
          </span>
          <img src="@/assets/icons/TuktukTheme/ic-nav-tuktuk--next--pink.svg" />
        </div>
      </div>
      <!-- DATA TYPE SIMPLE -->
      <div
        v-if="data.type == 'simple'"
        class="theme--tuktuk__checkout__order-summary__list__item"
      >
        <div
          class="theme--tuktuk__checkout__order-summary__list__item__product"
          :class="'theme--tuktuk__checkout__order-summary__list__item__product--show-thumbnail'"
        >
          <div
            class="theme--tuktuk__checkout__order-summary__list__item__product__thumbnail"
          >
          <div class="left__main__product-image__container--single">           


              <img   :src="data.productImages.length >= 1 ? getSizedImageUrl(data.productImages[0].src,'thumbnail','product-images')   :  require('@/assets/icons/variant-default-img.svg')
                " style="max-width: 100%;" />

            </div>
        </div>
          <div
            class="theme--tuktuk__checkout__order-summary__list__item__product__content"
          >
            <p
              class="theme--tuktuk__checkout__order-summary__list__item__product__name"
            >
              {{ data.name }}
            </p>
            <div
              v-if="data.type == 'simple'"
              class="theme--tuktuk__checkout__order-summary__list__item__product__price"
            >

            <template v-if="data.priceCompare">
              <div class="theme--tuktuk__checkout__order-summary__list__item__product__price__sales_container">
                <p class="theme--tuktuk__checkout__order-summary__list__item__product__price__sales">
                Rp{{ data.cartPrice | g_number_format }}
              </p>
              <p class="theme--tuktuk__checkout__order-summary__list__item__product__price__regular">
                <template v-if="data.priceCompare && data.type == 'simple'">
                  Rp{{ (data.cartQuantity * data.price) | g_number_format }}
                </template>
                <template v-else-if="parseInt(data.priceWithoutWhosale) !== parseInt(data.cartPrice)">
                  Rp{{ data.priceWithoutWhosale | g_number_format }}
                </template>
              </p>
          </div>
            </template>
            <template v-else>
              <p class="theme--tuktuk__checkout__order-summary__list__item__product__price__regular">
              Rp{{ data.cartPrice | g_number_format }}
            </p>      
          </template>

          
            </div>
          </div>
        </div>
        <div class="action-qty">
          <button @click="decrement(0, 'singleVariant')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="2"
              viewBox="0 0 11 2"
              fill="none"
            >
              <path opacity="0.2" d="M0.5 1H10.5" stroke="black" />
            </svg>
          </button>
          <vs-input min="0" v-model="data.cartQuantity" />
          <button @click="increment(0, 'singleVariant')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path d="M0.874084 6H11.1259" stroke="black" />
              <path d="M6 0.874146L6 11.1259" stroke="black" />
            </svg>
          </button>
        </div>
      </div>

      <!-- DATA TYPE NOT SIMPLE -->
      <template v-if="data.type != 'simple'">
        <!-- <h2 class="theme--tuktuk__title">
          {{ data.name }}
        </h2> -->
        
        <template
          v-if="
            !data.multiplyVariations ||
            (data.multiplyVariations && data.cartOrder)
          "
        >
          <template
            v-if="
              data.cartSummary &&
              data.cartSummary.length > 0 &&
              data.multiplyVariations
            "
          >
            <template v-for="(elem, i) in data.cartSummary">
              <div
                v-if="elem.qty"
                v-bind:key="i"
                class="theme--tuktuk__checkout__order-summary__item--multivariant"
              >
                <!-- CONTENT -->               
                <div class="theme--tuktuk__checkout__order-summary__list__item">
                  <div
                    class="theme--tuktuk__checkout__order-summary__list__item__product"                 
                    :class='!!elem.image || data.productImages.length > 0 ? "theme--tuktuk__checkout__order-summary__list__item__product--show-thumbnail" : ""'
                  >
                    <div
                      class="theme--tuktuk__checkout__order-summary__list__item__product__thumbnail"  v-if="!!elem.image || data.productImages.length >= 1"
                    >       
                    <img :src="getImageSrc(elem)" style="max-width: 100%;" />
                  </div>
                    <div
                      class="theme--tuktuk__checkout__order-summary__list__item__product__content"
                    >
                      <p
                        class="theme--tuktuk__checkout__order-summary__list__item__product__name"
                      >
                        {{ data.name }} 
                        <template v-for="(elemChild, j) in elem.items">
                          - {{ j.substring(0, j.length - 1) }} {{ elemChild.name }}
                        </template>
                      </p>

                      <div class="theme--tuktuk__checkout__order-summary__list__item__product__price">
                        <template
                        v-if="
                        getSubPrice(elem) > 0 &&
                        getSubPrice(elem) !==
                              elem.priceOriginal * elem.qty
                        "
                        >
                        <h2 class="theme--tuktuk__checkout__order-summary__list__item__product__price__sales">
                          Rp{{ getSubPrice(elem) | g_number_format }}

                        </h2>
                        <div
                          class="theme--tuktuk__checkout__order-summary__list__item__product__price__sales_container"
                        >
                          <p class="theme--tuktuk__checkout__order-summary__list__item__product__price__regular">
                            <template>
                            
                              Rp{{(elem.priceOriginal * elem.qty) | g_number_format }}
                            </template>
                          </p>                  
                        </div>
                        </template>
                        <template v-else>
                        <h2 class="theme--tuktuk__checkout__order-summary__list__item__product__price__regular">
                          
                          Rp{{ (elem.priceOriginal * elem.qty)| g_number_format }}
                        </h2>
                        </template>            


                        </div>

                      <!-- <div
                        class="theme--tuktuk__checkout__order-summary__list__item__product__price"
                      >
                        <p
                          v-if="elem.price == 0"
                          class="theme--tuktuk__checkout__order-summary__list__item__product__price__regular"
                        >
                          Rp{{
                            (elem.priceOriginal * elem.qty) | g_number_format
                          }}
                        </p>
                        <template v-if="elem.priceOriginal && elem.price > 0">
                          <p
                            class="theme--tuktuk__checkout__order-summary__list__item__product__price__regular"
                          >
                            Rp{{ getSubPrice(elem) | g_number_format }}
                          </p>
                          <p
                            class="theme--tuktuk__checkout__order-summary__list__item__product__price__sales"
                            v-if="
                              getSubPrice(elem) !==
                              elem.priceOriginal * elem.qty
                            "
                          >
                            Rp{{
                              (elem.priceOriginal * elem.qty) | g_number_format
                            }}
                          </p>
                        </template>
                      </div> -->
                    </div>
                  </div>
                  <!-- VARIANT ACTION QTY -->
                  <div class="action-qty">
                    <button @click="decrement(i, 'multipleVariant')">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="2"
                        viewBox="0 0 11 2"
                        fill="none"
                      >
                        <path opacity="0.2" d="M0.5 1H10.5" stroke="black" />
                      </svg>
                    </button>
                    <vs-input
                      min="0"
                      :value="
                        data.cartSummary[i] ? data.cartSummary[i].qty : elem.qty
                      "
                      readonly
                    />
                    <button @click="increment(i, 'multipleVariant')">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path d="M0.874084 6H11.1259" stroke="black" />
                        <path d="M6 0.874146L6 11.1259" stroke="black" />
                      </svg>
                    </button>
                  </div>
                </div>
                <!-- DELETE BUTTON -->
                <div v-if="data.cartSummary.length > 1"
                  class="theme--tuktuk__checkout__order-summary__item--multivariant__button-delete"
                >
                  <button @click="removeFromCart(i)" >
                    <img src="@/assets/icons/Delete-grey.svg" alt="delete" />
                  </button>
                </div>
              </div>
            </template>
          </template>

          <div v-else class="theme--tuktuk__checkout__order-summary__list__item">
            <!-- CONTENT -->           
            <div
              class="theme--tuktuk__checkout__order-summary__list__item__product"
              :class='!!getImageUrl || data.productImages.length > 0  ? "theme--tuktuk__checkout__order-summary__list__item__product--show-thumbnail" : ""'
            
            >
              <div
                class="theme--tuktuk__checkout__order-summary__list__item__product__thumbnail" v-if="!!getImageUrl || data.productImages.length >0"
              > 
                
                    <img :src="getImageSrc(getSelectedAttr)" style="max-width: 100%;" />
                  
                  </div>
              <div
                class="theme--tuktuk__checkout__order-summary__list__item__product__content"
              >
                <p
                  class="theme--tuktuk__checkout__order-summary__list__item__product__name"
                >
                  {{ data.name }}
                  <template v-for="(vprice) in Object.keys(
                      data.payload.variablePrices
                    )">
                      - {{ vprice.substring(0, vprice.length - 1) }} {{ data.payload.variablePrices[vprice].name }}
                  </template>
                  <!-- {{
                    data.payload.variablePrices
                      .map(
                        (vprice) =>
                          vprice.substring(0, vprice.length - 1) +
                          " - " +
                          data.payload.variablePrices[vprice].name
                      )
                      .join(" - ")
                  }} -->
                </p>
                <div class="theme--tuktuk__checkout__order-summary__list__item__product__price">
                  <template
                  v-if="
                    parseInt(data.priceWithoutWhosale) !==
                      parseInt(data.cartPrice)
                  "
                  >
                  <h2 class="theme--tuktuk__checkout__order-summary__list__item__product__price__sales">
                    Rp{{ data.cartPrice | g_number_format }}

                  </h2>
                  <div
                    class="theme--tuktuk__checkout__order-summary__list__item__product__price__sales_container"
                  >
                    <p class="theme--tuktuk__checkout__order-summary__list__item__product__price__regular">
                      <template>
                      
                        Rp{{data.priceWithoutWhosale | g_number_format }}
                      </template>
                    </p>                  
                  </div>
                  </template>
                  <template v-else>
                  <h2 class="theme--tuktuk__checkout__order-summary__list__item__product__price__regular">
                    
                    Rp{{ data.priceWithoutWhosale | g_number_format }}
                  </h2>
                  </template>            


                  </div>
                <!-- <div class="theme--tuktuk__checkout__order-summary__list__item__product__price">
                  <p class="theme--tuktuk__checkout__order-summary__list__item__product__price__regular">Rp{{ data.cartPrice | g_number_format }}</p>
                  <p v-if="
                      parseInt(data.priceWithoutWhosale) !==
                      parseInt(data.price)
                    "
                    class="theme--tuktuk__checkout__order-summary__list__item__product__price__sales"
                  >
                    Rp{{ data.priceWithoutWhosale | g_number_format }}
                  </p>
                </div> -->
                
              </div>
            </div>
            <!-- VARIANT ACTION QTY -->
            <div class="action-qty">
              <button @click="decrement(0, 'singleVariant')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="2"
                  viewBox="0 0 11 2"
                  fill="none"
                >
                  <path opacity="0.2" d="M0.5 1H10.5" stroke="black" />
                </svg>
              </button>
              <vs-input min="0" v-model="data.cartQuantity" readonly />
              <button @click="increment(0, 'singleVariant')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path d="M0.874084 6H11.1259" stroke="black" />
                  <path d="M6 0.874146L6 11.1259" stroke="black" />
                </svg>
              </button>
            </div>
          </div>
        </template>

        <!-- VARIANT ORDER MULTIPLY VARIATION -->
        <template v-else>
          <template>
            <template v-for="(attr, i) in data.variablePrices">
              <div
                v-if="attr.qty > 0"
                :key="'f' + i"
                class="theme--tuktuk__checkout__order-summary__item--multivariant"
              >
                <!-- CONTENT -->
                <div class="theme--tuktuk__checkout__order-summary__list__item">
                  <div class="theme--tuktuk__checkout__order-summary__list__item__product"
                    :class='!!attr.image || data.productImages.length >= 1 ? "theme--tuktuk__checkout__order-summary__list__item__product--show-thumbnail" : ""'
                   >
                    <div
                      class="theme--tuktuk__checkout__order-summary__list__item__product__thumbnail" v-if="!!attr.image || data.productImages.length > 0">
                    <img  :src="getImageSrc(attr)" style="max-width: 100%;" />
                  
                  </div>
                    <div class="theme--tuktuk__checkout__order-summary__list__item__product__content">
                      <p
                      class="theme--tuktuk__checkout__order-summary__list__item__product__name"
                      >
                        {{ data.name }}
                        <template v-for="(n) in attr.attrs">
                            - {{ n.name.substring(0, n.name.length - 1) }} {{ n.value }}
                        </template>
                        <!-- {{
                          attr.attrs
                            .map(
                              (n) =>
                                n.name.substring(0, n.name.length - 1) +
                                " " +
                                n.value
                            )
                            .join(" - ")
                        }} -->
                      </p>
                      <div class="theme--tuktuk__checkout__order-summary__list__item__product__price">
                          <template
                          v-if="
                            getSubPrice(attr) !== attr.price * attr.qty
                          "
                          >
                          <h2 class="theme--tuktuk__checkout__order-summary__list__item__product__price__sales">
                            Rp{{ getSubPrice(attr) | g_number_format }}

                          </h2>
                          <div
                            class="theme--tuktuk__checkout__order-summary__list__item__product__price__sales_container"
                          >
                            <p class="theme--tuktuk__checkout__order-summary__list__item__product__price__regular">
                              <template>
                              
                                Rp{{ (attr.price * attr.qty) | g_number_format }}
                              </template>
                            </p>                  
                          </div>
                          </template>
                          <template v-else>
                          <h2 class="theme--tuktuk__checkout__order-summary__list__item__product__price__regular">
                            
                            Rp{{ (attr.price * attr.qty) | g_number_format }}
                          </h2>
                          </template>            


                          </div>

                      <!-- <div class="theme--tuktuk__checkout__order-summary__list__item__product__price">
                        <p class="theme--tuktuk__checkout__order-summary__list__item__product__price__regular">Rp{{ getSubPrice(attr) | g_number_format }}</p>
                        <p
                          v-if="getSubPrice(attr) !== attr.price * attr.qty"
                          class="theme--tuktuk__checkout__order-summary__list__item__product__price__sales"
                        >
                          Rp{{ (attr.price * attr.qty) | g_number_format }}
                        </p>
                      </div> -->
                    </div>
                  </div>

                  <!-- VARIANT ACTION QTY -->
                  <div class="action-qty">
                    <button @click="decrement(i, 'multipleVariant')">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="2"
                        viewBox="0 0 11 2"
                        fill="none"
                      >
                        <path opacity="0.2" d="M0.5 1H10.5" stroke="black" />
                      </svg>
                    </button>
                    <vs-input
                      min="0"
                      :value="attr.qty"
                      readonly
                    />
                    <button @click="increment(i, 'multipleVariant')">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path d="M0.874084 6H11.1259" stroke="black" />
                        <path d="M6 0.874146L6 11.1259" stroke="black" />
                      </svg>
                    </button>
                  </div>
                </div>
                <!-- DELETE BUTTON -->
                <div v-if="attr.qty > 0"
                  class="theme--tuktuk__checkout__order-summary__item--multivariant__button-delete"
                >
                  <button @click="deleteVariant(i)" >
                    <img src="@/assets/icons/Delete-grey.svg" alt="delete" />
                  </button>
                </div>
              </div>
            </template>
          </template>
        </template>        
      </template>
    </div>   
    <no-ssr>
      <vs-dialog v-if="!data.embed" overflow-hidden class="theme--tuktuk__modal tuktuk-modal-variant" :class="(!data.multiplyVariations ||
        (data.multiplyVariations)) && data.showProductImages && (data.variantImageIndex > -1 || data.productImages.length > 0)
        ? 'tuktuk-modal-variant--show-thumbnail'
        : ''
        " v-model="chartModal">
        <SelectProduct @updateCartQuantity="updateCartQuantity" :data="{ ...data }" :payload="data.payload"  :itemIdex="selectedVariantIndex"
              @addToCart="addToCart" @showMsg="showMsg" :isVariantModalOpen="isVariantModalOpen" @setStep="setStep" @changeVariant="changeVariant" @increment="incrementSelect" @decrement="decrementSelect" @addCartItem="addCartItem" />
      </vs-dialog>

      <div v-else class="theme--tuktuk__modal select-options--tuktuk-modal tuktuk-modal-variant"
        :class="{
          'theme--tuktuk__modal--show': chartModalEmbed, 'select-options--tuktuk-modal--show-thumbnail': (!data.multiplyVariations ||
            (data.multiplyVariations)) && data.showProductImages && (data.variantImageIndex > -1 || data.productImages.length > 0)
        }">
        <div class="theme--tuktuk__modal__overflow" @click="chartModalEmbed = false"></div>
        <SelectProduct ref="SelectProduct" @updateCartQuantity="updateCartQuantity" :data="{ ...data }" :payload="data.payload" :itemIdex="selectedVariantIndex"
              @addToCart="addToCart" @showMsg="showMsg" :isVariantModalOpen="isVariantModalOpen" @setStep="setStep" @changeVariant="changeVariant" @increment="incrementSelect" @decrement="decrementSelect" @addCartItem="addCartItem"/>
      </div>
    </no-ssr>
    <!-- END ORDER SUMMARY LIST -->
  </div>
</template>

<script>
import config from "./../../../../config.js";
import { getSizedImageUrl } from "@/helpers/imagekitHelper";
import SelectProduct from "@/components/Form/Tuktuk/ProductDetails/SelectProduct";
export default {
  props: ["data","isVariantModalOpen"],
  data() {
    return {
      baseUrl: config.api.replace("/api", ""),
      getSizedImageUrl,
      chartModal:false,
      chartModalEmbed:false,
      selectedVariantIndex: 0,   
    };
  },
  components: {
    SelectProduct
  },
  computed:{
    getImageUrl(){
      let getSelectedVariant = this.getSelectedVariantName(
        this.data.payload.variablePrices
      );
      let priceVar = this.data.variablePrices.filter(
        (e) => e.name == getSelectedVariant
      );
      return priceVar.length ? priceVar[0].image : "";
    },
    getSelectedAttr(){
      let getSelectedVariant = this.getSelectedVariantName(
        this.data.payload.variablePrices
      );
      let priceVar = this.data.variablePrices.filter(
        (e) => e.name == getSelectedVariant
      );     
      return priceVar.length ? priceVar[0] : {};
    },
    dataVariablePrices() {
      if (this.data.hideUnavailableProductVariant) {
        return this.data.variablePrices.filter(variablePrice => !variablePrice.delete);
      }

      return this.data.variablePrices;
    },
  },

  methods: {
    addToCart(val) {
      this.$emit("addToCart",val);
    },
    changeVariant() {
      setTimeout(()=>{
        if(this.data.embed && this.data.themeType=='TuktukStep1'){        
          this.$refs['SelectProduct'].$el.scrollIntoView({ behavior: 'smooth' }) 
        }  
      },200)      
      this.$emit("changeVariant");
    },
    setStep() {
      this.$emit("changeVariant");
      this.chartModal=false;
      this.chartModalEmbed=false;
     // this.$emit("setStep");
    },
    showMsg() {
      this.$emit("showMsg");
    },
    openChooseVariantModal(item) {       
      this.changeVariant = true;      
      this.chartModalEmbed = true;      
      if ((!this.data.multiplyVariations || (this.data.multiplyVariations && this.data.cartOrder)) && item) {
        item.attrs.map((attr) => {
          this.data.payload.variablePrices[
            attr.name
          ].name = attr.value

        })
      }
      let selectedVariant = this.getSelectedVariantName(this.data.payload.variablePrices)      
     
      if(this.data.cartSummary.length > 0 && this.data.multiplyVariations && this.data.cartOrder && !this.data.tuktukThemeSetting.TuktukSettingsAddTOCart){
        let priceVar = this.data.cartSummary.find(
          (e) => e.name == selectedVariant
        );         
        this.data.cartQuantity=priceVar && priceVar.qty ? priceVar.qty: !this.data.tuktukThemeSetting.TuktukSettingsAddTOCart ? 0 : 1;      
      }        
      this.$emit("changeVariant", {status:true,elem:item});
    },
    getImageSrc(elem){   
      let variantImageIndex =-1; 
      if(elem && elem.attrs){
        variantImageIndex= this.data.variablePrices && this.data.variablePrices.findIndex(
          (x) => elem && elem.attrs  && x.image && x.name && x.name.indexOf(elem.attrs[0].value+" / ") > -1
        ); 
      }
          
      if(variantImageIndex==-1){
        variantImageIndex =this.data.variablePrices && this.data.variablePrices.findIndex(
          (x) => elem && elem.items  && x.image && x.name && x.name.indexOf(elem.items[Object.keys(elem.items)[0]].name+" / ") > -1
        );
      }             
      if(elem && elem.image) return getSizedImageUrl(elem.image,'medium','product-images');     
      else if(elem && typeof elem=='string' && elem.indexOf("/product-images/") > -1) return getSizedImageUrl(elem,'medium','product-images');     
      else if (variantImageIndex > -1  && this.data.variablePrices[variantImageIndex].image) return getSizedImageUrl(this.data.variablePrices[variantImageIndex].image,'medium','product-images');      
      else if (this.data.productImages.length>0 ) return getSizedImageUrl(this.data.productImages[0].src,'medium','product-images'); 
      
      else return require('@/assets/icons/variant-default-img.svg');   
    },
    getSelectedVariantName(param) {
      let selectedVariant = Object.values(param);
      selectedVariant = selectedVariant.map((data) => {
        return data.name;
      });
      return selectedVariant
        .toString()
        .replace(/[,]/g, " / ");
    },
    removeFromCart(index) {     
      if (this.data.cartSummary.length) {      
        this.$emit("updateCartSummary", index);
      }
    },
    deleteVariant(index) {
      if (this.data.variablePrices) {
        this.data.variablePrices[index].qty = 0;
      }
      this.$forceUpdate();
    },
    increment(val, type) {
      if (
        type == "multipleVariant" &&
        this.data.cartOrder &&
        this.data.addQuantity
      ) {
        if (this.data.cartSummary[val]) {
          this.data.cartSummary[val].qty =
            parseInt(this.data.cartSummary[val].qty) + 1;
        }
        this.updateCartQuantity();
      } else if (type == "multipleVariant" && !this.data.cartOrder) {
        if (this.data.variablePrices[val]) {
          this.data.variablePrices[val].qty =
            parseInt(this.data.variablePrices[val].qty) + 1;            
        }
        this.updateCartQuantity();
      } else if (type == "singleVariant") {
        this.data.cartQuantity = parseInt(this.data.cartQuantity) + 1;
        this.updateCartQuantity();
      }
      this.$forceUpdate();
    },
    decrement(val, type) {
      if (
        type == "multipleVariant" &&
        this.data.cartOrder &&
        this.data.addQuantity
      ) {
        if (
          this.data.cartSummary[val] &&
          this.data.cartSummary[val].qty &&
          this.data.cartSummary[val].qty > 0
        ) {
          if (this.data.cartSummary[val].qty < 2 || (this.data.cartSummary[val].isActiveMinimumPurchase && this.data.cartSummary[val].minimumPurchase >=this.data.cartSummary[val].qty)) {
            return;
          } else {
            this.data.cartSummary[val].qty = this.data.cartSummary[val].qty - 1;
          }
          this.updateCartQuantity();
        }
      } else if (type == "multipleVariant" && !this.data.cartOrder) {
        if (
          this.data.variablePrices[val].qty &&
          this.data.variablePrices[val].qty > 0
        ) {
          if (this.data.variablePrices[val].qty < 2) {
            return;
          } else {
            this.data.variablePrices[val].qty =
              this.data.variablePrices[val].qty - 1;
          }
        }
      } else if (type == "singleVariant") {
        if (this.data.cartQuantity < 2) {
          return;
        } else {
          this.data.cartQuantity = parseInt(this.data.cartQuantity) - 1;
        }
        this.updateCartQuantity();
      }

      this.$forceUpdate();
    },
    addCartItem() {
      this.$emit("addCartItem");
    },
    incrementSelect(val, type) {
      if (type == "multipleVariant" && !this.data.cartOrder) {
        if (this.dataVariablePrices[val]) {
          this.dataVariablePrices[val].qty =
            parseInt(this.dataVariablePrices[val].qty) + 1;
        }
        if(this.dataVariablePrices[val].qty==1){
          this.addCartItem();
        }
        
      } else if (type == "singleVariant") {
        this.data.cartQuantity = this.data.cartQuantity + 1;
        this.updateCartQuantity();
      }
      this.$forceUpdate();
      if(this.data.cartOrder &&  this.data.multiplyVariations &&  !this.data.tuktukThemeSetting.TuktukSettingsAddTOCart){
          setTimeout(() => {
            this.addToCart('update')
          },500);   
        }
    },
    decrementSelect(val, type) {
      if (type == "multipleVariant" && !this.data.cartOrder) {
        if (
          this.dataVariablePrices[val].qty &&
          this.dataVariablePrices[val].qty > 0
        ) {
          if (this.dataVariablePrices[val].qty < 1) {
            return;
          } else {
            this.dataVariablePrices[val].qty =
              this.dataVariablePrices[val].qty - 1;
          }
        }
        
      } else if (type == "singleVariant") {
        if (this.data.cartQuantity < 2) {
          if(this.data.cartSummary.length>1){
              if (this.data.cartQuantity < 1) {
                return;
              } else {       
                
                let selectedVariant = this.getSelectedVariantName(this.data.payload.variablePrices)     
                if(this.data.cartSummary.length > 0 && this.data.multiplyVariations && this.data.cartOrder){
                  let priceVarIndex = this.data.cartSummary.findIndex(
                    (e) => e.name == selectedVariant
                  );
                  this.data.cartQuantity=0;
                  this.updateCartQuantity()
                  this.removeFromCart(priceVarIndex);
                }  
                
              }
            }
          return;        
        } else {
          this.data.cartQuantity = this.data.cartQuantity - 1;
        }
        this.updateCartQuantity();
      }
      if(this.data.cartOrder &&  this.data.multiplyVariations &&  !this.data.tuktukThemeSetting.TuktukSettingsAddTOCart){
          setTimeout(() => {
            this.addToCart('update')
          },500);   
        }
      this.$forceUpdate();
    },

    updateCartQuantity() {
      this.$emit("updateCartQuantity", this.data.cartQuantity);
    },
    getSubPrice(attr) {
      let price = 0;
      if (attr.qty) {
        let subPrice = attr.priceCompare ? attr.priceCompare : attr.price;
        if (attr.whosaleData) {
          attr.whosaleData.map((one) => {
            if (one.count && parseInt(one.count) <= parseInt(attr.qty)) {
              subPrice = one.price;
            }
          });
        }
        price = price + subPrice * attr.qty;
      }
      return price;
    },
  },
  watch:{
    "chartModal": {
      handler(val) {        
        this.$emit("changeVariant", val);
      },
    },
    "isVariantModalOpen":{
      handler(val) {
        this.changeVariant = val;
        this.chartModalEmbed = val;      
      }
    },
    "chartModalEmbed": {
      handler(val) {        
        this.$emit("changeVariant", val);
      }
    }, 
    "data.changeVariant": {
      handler(val) {        
        this.chartModal=val;
        this.chartModalEmbed=val;
        if(this.data.changeVariantItem && this.data.changeVariantItem.items){         
          Object.keys(this.data.changeVariantItem.items).map(key=>{
            this.data.payload.variablePrices[
            key
              ].name = this.data.changeVariantItem.items[key].name;

          });     
        }    
        if (this.data.changeVariantItem && this.data.changeVariantItem.attrs){
        
          this.selectedVariantIndex = this.data.variablePrices.findIndex(item => item.name === this.data.changeVariantItem.name);               
        }else if(this.data.changeVariantItem && this.data.changeVariantItem.items){
          
          this.selectedVariantIndex = this.data.variablePrices.findIndex(item => item.name === this.data.changeVariantItem.items);          
        }
      }
    }, 
  }
};
</script>

<style scoped>
.select-options--tuktuk__button__right-side {
  color: #EB435B;
  cursor: pointer;
}
</style>
