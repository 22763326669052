<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import config from "../config.js";
import { getSizedImageUrl } from "@/helpers/imagekitHelper";

export default {
  props: ["images", "type", "variantImageIndex","nuxt2"],
  data() {  
    return {
      baseUrl: config.api.replace("/api/", ""),
      customWidth: (this.nuxt2 && process.client) || !this.nuxt2 ? window.innerWidth : 800,
      testimonialEmbedModal: false,
      initializeModalCarouselFrom: 0,
      getSizedImageUrl,
      touchPosition: {
        x: 0,
        y: 0,
      }
    };
  },
  components: {
    VueSlickCarousel,
  },
  methods: {
    openCarousel(index) {
      this.testimonialEmbedModal = true;
      this.initializeModalCarouselFrom = index;
    },
    // Function to set equal height
    cbar_equalHeights(objClass) {
      var elements = document.querySelectorAll(objClass);
      // Reset heights to 'auto'
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.height = "auto";
      }

      var maxHeight = 0;
      for (let i = 0; i < elements.length; i++) {
        var height = elements[i].offsetHeight;
        if (height > maxHeight) {
          maxHeight = height;
        }
      }

      // Set the height to the maximum value
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.height = maxHeight + "px";
      }
    },
  },
  watch: {
    testimonialEmbedModal: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            // for equal height of images in testimonial popup
            this.cbar_equalHeights(".testimonial-modal-img");
          }, 100);
        }
      },
    },
    "images.src": {
      handler() {
        // console.log("here",this.$refs.carousel,this.images.length,this.variantImageIndex);
        if (this.variantImageIndex > -1) {
          this.$refs.carousel.goTo(this.variantImageIndex);
        }
      },
      deep: true,
    },
  },
};
</script>

<template>
  <div>
    <VueSlickCarousel
      ref="carousel"
      class="custom-carousel "
      :class="type == 'testimonial' ? 'carousel-testimoni' : type=='testimonial--tuktuk'? 'carousel-testimoni': type == 'product--tuktuk' ? 'carousel-tuktuk': 'carousel-product'"
      :arrows="type == 'product--tuktuk' ? false : true"
      :dots="type == 'testimonial' || type=='testimonial--tuktuk' || type == 'product--tuktuk' ? false : true"
      :adaptiveHeight="type == 'testimonial' ||type=='testimonial--tuktuk'? false : true"
      :autoplay="false"
      :dotsClass="'slick-dots custom-dot-class'"
      :infinite="type == 'testimonial'||type=='testimonial--tuktuk' ? false : true"
      :draggable="type != 'testimonial'||type!='testimonial--tuktuk'"
      :swipeToSlide="type != 'testimonial'||type!='testimonial--tuktuk'"
      :initialSlide="0"
      :slidesToShow="
        type == 'testimonial' ? (customWidth < 768 ? 2.5 : 4.5) : type=='testimonial--tuktuk'? 3.5 : 1
      "
      :slidesToScroll="type == 'testimonial'||type=='testimonial--tuktuk' || type == 'product--tuktuk' ? 0.5 : 1"
    >
      <!-- CUSTOMIZE ARROW -->
      <template #prevArrow>
        <div class="testi-arrows" v-if="type == 'testimonial' ">
          <img src="@/assets/icons/slick-left-arrow-testi-new.png" alt="left-arrow" />
        </div>
        <div class="testi-arrows" v-else-if="type=='testimonial--tuktuk'">
          <img src="@/assets/icons/TuktukTheme/ic-nav-tuktuk--prev-circle-pink.png" alt="left-arrow" />
        </div>
        <div v-else class="custom-arrow">
          <img src="@/assets/icons/slick-left-arrow-new.png" alt="left-arrow" />
        </div>
      </template>

      <template #nextArrow>
        <div class="testi-arrows" v-if="type == 'testimonial'">
          <img src="@/assets/icons/slick-right-arrow-testi-new.png" alt="left-arrow" />
        </div>
        <div class="testi-arrows" v-else-if="type=='testimonial--tuktuk'">
          <img src="@/assets/icons/TuktukTheme/ic-nav-tuktuk--next-circle-pink.png" alt="right-arrow" />
        </div>
        <div v-else class="custom-arrow">
          <img
            src="@/assets/icons/slick-right-arrow-new.png"
            alt="right-arrow"
          />
        </div>
      </template>

      <!-- IMAGE CONTENT -->
      <template v-if="type == 'product--tuktuk'">
        <div :key="tm" v-for="(image, tm) in images" class="carousel-tuktuk__container">
          <div class="carousel-tuktuk__number-image">
            {{ `${tm + 1}/${images.length}` }}
          </div>
          <img
            class="w-100 image-carousel"
            style="border: 1px solid #d9dee2"
            :src="getSizedImageUrl(image.src,'fullView','product-images')"
          />
        </div>
      </template>
      <template v-else-if="type == 'product'">
        <div :key="tm" v-for="(image, tm) in images" class="carousel-product__container">
          <img
            class="w-100 image-carousel"
            style="border: 1px solid #d9dee2"
            :src="getSizedImageUrl(image.src,'fullView','product-images')"            
          />
        </div>
      </template>
      <template v-else>
        <img
          :key="tm"
          v-for="(image, tm) in images"
          class="w-100 image-carousel"
          style="border: 1px solid #d9dee2"
          :src="
            type == 'testimonial' || type=='testimonial--tuktuk'
              ? image.avatar
                ? getSizedImageUrl(image.avatar,'fullView','product-images')
                : getSizedImageUrl(image,'fullView','testimonial-images')
              : (type == 'product' || type == 'product--tuktuk') && image.src
              ? getSizedImageUrl(image.src,'fullView','product-images')
              : getSizedImageUrl(image,'fullView','product-images') 
          "
          :class="type == 'testimonial' || type=='testimonial--tuktuk'? 'embed-testimonial-image pointer' : ''"
          @click="type == 'testimonial' || type=='testimonial--tuktuk'? openCarousel(tm) : ''"
        />
      </template>
      
    </VueSlickCarousel>

    <!-- TESTIMONIAL CAROUSEL MODAL -->
    <vs-dialog
      v-if="type =='testimonial'"
      overflow-hidden
      width="680px"
      v-model="testimonialEmbedModal"
      class="pp-modal-carousel-testimoni"
    >
      <div class="pp-modal-carousel-testimoni__container">
        <div >
          <VueSlickCarousel
            :arrows="true"
            :dots="false"
            :adaptiveHeight="false"
            :autoplay="false"
            :infinite="false"
            :dotsClass="'slick-dots custom-dot-class'"
            :draggable="false"
            :initialSlide="initializeModalCarouselFrom"
            :slidesToShow="1"
          >
            <!-- Customize arrow -->
            <template #prevArrow>
              <div class="testi-arrows-modal">
                <div class></div>
                <img
                    src="@/assets/icons/slick-left-arrow-new.png"
                    alt="right-arrow"
                  />
              </div>
            </template>
            <template #nextArrow>
              <div class="testi-arrows-modal">
                <img
                    src="@/assets/icons/slick-right-arrow-new.png"
                    alt="right-arrow"
                  />
              </div>
            </template>
            <div v-for="(image, im) in images" :key="im" class="testimonial-modal-img">
              <img
                class="modal-img"
                :src="
                  image.avatar 
                    ? getSizedImageUrl(image.avatar,'fullView','product-images') 
                    : getSizedImageUrl(image,'fullView','testimonial-images') 
                "
              />
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </vs-dialog>

    <vs-dialog
      v-if="type=='testimonial--tuktuk'"
      overflow-hidden
      v-model="testimonialEmbedModal"
      class="pp-modal-carousel-testimoni theme--tuktuk__modal-testimoni"
    >
      <div class="theme--tuktuk__modal__container pp-modal-carousel-testimoni__container">
        <div class="theme--tuktuk__modal__header">
          <h4>{{ $t('common.Testimonials') }}</h4>
        </div>
        <div class="theme--tuktuk__modal__main">
          <VueSlickCarousel
            :arrows="true"
            :dots="true"
            :adaptiveHeight="false"
            :autoplay="false"
            :infinite="true"
            :draggable="true"
            :initialSlide="initializeModalCarouselFrom"
            :slidesToShow="1"
          >
            <!-- Customize arrow -->
            <template #prevArrow>
              <div class="testi-arrows-modal">
                <img src="@/assets/icons/TuktukTheme/ic-nav-tuktuk--prev-circle-white.svg" alt="left-arrow" />
              </div>
            </template>
            <template #nextArrow>
              <div class="testi-arrows-modal">
                <img  src="@/assets/icons/TuktukTheme/ic-nav-tuktuk--next-circle-white.svg" alt="left-arrow" />
              </div>
            </template>
            <img
              v-for="(image, im) in images" :key="im"
              class="modal-img"
              :src="
                image.avatar
                  ? getSizedImageUrl(image.avatar,'fullView','product-images') 
                  : getSizedImageUrl(image,'fullView','testimonial-images') 
              "
            />
          </VueSlickCarousel>
        </div>
        <div class="theme--tuktuk__modal__footer"></div>
      </div>
    </vs-dialog>
  </div>
</template>

<style lang="scss">
.carousel-product{
  
  .slick-dots{
    padding-bottom: 10px;
  }
  .slick-list {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    .slick-slide {
      aspect-ratio: 1/1;
      border-radius: 8px;
      background-color: #ffffff;
      overflow: hidden;
      width: 100%;


      & > div {
        height: 100%;
        width: 100%;
      }
      .carousel-product__container{
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          transition: transform 0.2s ease-out;
          transform-origin: center;
          user-drag: none;
          -webkit-user-drag: none;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          
        }
       
      }
      
    }
  }
}

.carousel-testimoni{
  .testi-arrows{
    &.slick-disabled{
      opacity: 0.5;
      cursor: default;
    }
    &.slick-arrow{
      height: auto;
      width: auto;
      top: -26%;
      @media screen and (min-width: 768px) {
        top: -21%;
      }
    }
    &.slick-prev {
      z-index: 1;
      left: unset;
      right: 34px;
    }
    &.slick-next {
      right: 0%;
    }
  }
  img.embed-testimonial-image {
    object-fit: cover !important;
    aspect-ratio: 1/1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 8px;
    cursor: pointer;
  }

}

.pp-modal-carousel-testimoni {
  .vs-dialog{
    width: 630px;
  }
  .vs-dialog__close {
    top: 25px !important;
    right: 25px !important;
    left: unset;
    bottom: unset;
    background: unset;
    box-shadow: none;
    &:hover{
      transform: unset;
    }

    .vs-icon-close {
      background-color: transparent;
      position: relative;
      &::before,
      &::after {
        top: 17px;
        right: 9px;
        left: unset;
        bottom: unset;
        background: #6C7E95;
      }
    }
  }
  .testi-arrows-modal {
    background-color: rgba(0, 0, 0, 0.35);
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    &.slick-arrow {
      border: 0;
      border-radius: 0;
    }
    &.slick-disabled{
      opacity: 0.5;
    }
    
    
  }

  .vs-dialog__content {
    padding: 10px;
    margin: 0;
    &.notFooter{
      margin: 0;
    }
    .slick-slide {
      aspect-ratio: 1/1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
      background-color: #F9F7FA;
      border-radius: 4px;
      overflow: hidden;
      & > div {
        height: 100%;
      }
      
    }
    .testimonial-modal-img {
      display: flex !important;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      height: 100% !important;

      img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        border-radius: 0;
        cursor: pointer;
        object-fit: contain;
      }
    }
  }
}

.theme--tuktuk__modal-testimoni{
  & .vs-dialog {
    border-radius: 8px !important;
    margin: auto !important;
    width: 100% !important;
    max-width: 285px !important;
    min-width: unset;
    
    @media screen and (max-width: 574px) {
      max-width: calc(100% - 50px) !important;
    }

    &__close {
      top: 14px !important;
      right: 15px !important;

      & .vs-icon-close {
        width: 20px;
        height: 20px;

        &::before,
        &::after {
          top: 9px;
          right: 3px;
          height: 1px;
          background-color: #000;
        }
      }
    }

    &__content {
      padding: 0px;
    }

    .theme--tuktuk__modal__header h4 {
      text-align: left;
      font-weight: 700;
      font-size: 15px;
      margin: 14px 15px;
    }

    .theme--tuktuk__modal__main {
      .slick-dots {
        bottom: -30px !important;
        display: flex !important;
        justify-content: center;
        overflow: auto;

        li {
          button:before {
            color: #D9D9D9 !important;
            opacity: 1;
          }

          &.slick-active button:before {
            color: #EB435B !important;
          }
        }
      }
    }

    .theme--tuktuk__modal__footer {
      height: 38px;
    }
  }

  .testi-arrows-modal {
    background-color: transparent;
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    box-shadow: 0px -14px 30px rgba(0, 0, 0, 0.05);
    &.slick-arrow {
      border: 0;
      border-radius: 0;
    }
    &.slick-disabled{
      opacity: 0.5;
    }
    &.slick-next{
      right: 10px !important;
    }
    &.slick-prev{
      left: 10px !important;
    }
  }

  &.theme--tuktuk__modal.vs-dialog-content .vs-dialog .vs-dialog__content .theme--tuktuk__modal__container{
    .theme--tuktuk__modal__main{
      max-height: unset;
    }
  }
  .slick-list{
    //background-color:rgba(32, 32, 32, 0.4);
    border-radius: 0;
    .slick-slide {
      height: 212px;
      border-radius: 0;
      overflow: hidden;
      
      & > div{
        height: 100%;
        img{
          height: 100%;
          width: 100%;
          object-fit: contain;
          user-drag: none;
          -webkit-user-drag: none;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
        }
      }
    }
  }
}


.carousel-tuktuk{
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  .slick-list {
    width: 100%;
    max-width: 100%;
    border-radius: 0;

    .slick-slide {
      aspect-ratio: 1/1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
      background-color: #F9F7FA;

      & > div {
        height: 100%;
        width: 100%;
      }
      .carousel-tuktuk__container{
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        .carousel-tuktuk__number-image{
          position: absolute;
          bottom: 20px;
          right: 20px;
          z-index: 1;
          display: flex;
          padding: 4px 12px;
          border-radius: 4px;
          background: rgba(32, 32, 32, 0.40);
          color: #FFF;
          text-align: center;
          font-family: TikTokDisplayBold;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 160%; /* 19.2px */
        }
      }
      img {
        object-fit: contain;
        max-width: 100%;
        height: 100%;
        border-radius: 0;
        height: 100% !important;
        transition: transform 0.2s ease-out;
        transform-origin: center;
        user-drag: none;
        -webkit-user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
      }
    }
  }

  .custom-carousel {
    .custom-arrow {
      width: 24px;
      height: 24px;
      background-color: rgba(0, 0, 0, 0.35);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      &.slick-disabled {
        opacity: 0.5;
      }
    }
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
  }
}
</style>

<style>

.slick-prev:before,
.slick-next:before {
  color: black;
  content: "";
}

.slick-next {
  /* right: -5px; */
  z-index: 1;
}

.custom-arrow img {
  pointer-events: none;
}

.testimonial-carousel .slick-slide {
  padding-right: 15px;
  height: auto;

}

.testimonial-carousel{
  padding-bottom: 0 !important;
}

.testi-arrows-modal.slick-arrow {
  border: 1px solid #2e47ba;
  border-radius: 50px;
}

.testi-arrows .prev-arrow,
.testi-arrows .next-arrow,
.testi-arrows-modal .prev-arrow,
.testi-arrows-modal .next-arrow {
  border: none;
  color: #2e47ba;
  position: absolute;
  top: 47%;
  left: 20%;
  font-size: 18px;
  background: none;
  opacity: 0.7;
}

.testi-arrows.slick-disabled .prev-arrow,
.testi-arrows.slick-disabled .next-arrow,
.testi-arrows-modal.slick-disabled .prev-arrow,
.testi-arrows-modal.slick-disabled .next-arrow {
  color: #62718f;
}

.testi-arrows-modal.slick-prev {
  left: 0 !important;
}
.testi-arrows-modal.slick-next {
  right: 0 !important;
}


@media screen and (max-width: 767px) {
  .dialog-mobile-margin {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
 
}
</style>
